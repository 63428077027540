import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
const Social = [
    {
        title: "Twitter",
        icon: <TwitterIcon />,
        url: "https://twitter.com/managedsolution",
    },
    {
        title: "Facebook",
        icon: <FacebookSharpIcon />,
        url: "https://www.facebook.com/managedsolution",
    },
    {
        title: "Youtube",
        icon: <YouTubeIcon />,
        url: "https://www.youtube.com/user/managedsolution",
    },
    {
        title: "LinkedIn",
        icon: <LinkedInIcon />,
        url: "https://www.linkedin.com/company/managedsolution",
    },
];

export default Social;
