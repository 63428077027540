/** @format */

import { Routes as Router, Route, BrowserRouter } from "react-router-dom";
import LandingPage from "./pages/ComingSoon/index";


const Routes = () => {
  return (
    <BrowserRouter>
      <Router>

        <Route path="/" element={<LandingPage />} />
      </Router>

    </BrowserRouter>
  );
};

export default Routes;
